<template>
	<div class="contact">
		

	<div class="contact-container">
	<h3>Contact</h3>
		<form @submit.prevent="send_message">
			<div class="form-label">Your Name:</div>
			<input type="text" v-model="msg.name" placeholder="(optional)"><br />
			<div class="form-label">Your Email:</div>
			<input type="text" v-model="msg.email" placeholder="(optional)"><br />
			<div class="form-label">Message:</div>
			<textarea v-model="msg.message" rows="3"></textarea>
			<br /><br />
			<div v-if="captcha_img">
				<img :src="captcha_img" /><br />
				<input type="text" name="captcha" autocomplete="off" v-model="captcha_input" />
				<div class="btn small" id="captcha-refresh-btn" @click="refresh_captcha">Refresh</div>
				<br />
				<br />
			</div>
			<button class="btn small submit-btn">Send</button>
		</form> 
	</div>

	</div>
</template>

<script>
import qs from 'qs'
import axios from 'axios'

export default {
	name: 'home',
	data () {
		return {
			CAPTCHA_API: 'https://zhenyuliao.com/api/message',
			captcha_img: '',
			captcha_key: '',
			captcha_input: '',
			msg: {
				name: '',
				email: '',
				message: ''
			}
		}
	},
	methods: {
		send_message () {
			let self = this
			if (!this.captcha_img) {
				this.refresh_captcha()
			} else {
				if (!this.msg.message) {
					alert('Please input the message')
					return
				}
				axios.post(self.CAPTCHA_API + '/send', qs.stringify({
					ckey: self.captcha_key,
					captcha: self.captcha_input,
					name: self.msg.name,
					email: self.msg.email,
					message: self.msg.message,
					to_email: 'zyliao.biz@gmail.com'
				}))
				.then(function (response) {
					if (response.data.result === 1) {
						alert(response.data.message)
						self.refresh_captcha()
					} else {
						alert('Message sent to Zhenyu!')
						self.captcha_img = ''
						self.captcha_key = ''
						self.captcha_input = ''
						self.msg = {
							name: '',
							email: '',
							message: ''
						}
					}
				})
				.catch(function (error) {
					console.log(error);
				});
			}
		},
		
		refresh_captcha () {
			let self = this
			let current_ckey = ''
			if (this.captcha_key) {
				current_ckey = '?ckey=' + this.captcha_key
			}
			axios.get(self.CAPTCHA_API + '/captcha_pre' + current_ckey, {})
			.then(function (response) {
				self.captcha_key = response.data.ckey
				self.captcha_img = self.CAPTCHA_API + '/captcha?ckey=' + self.captcha_key
			})
			.catch(function (error) {
				console.log(error)
			});
		}
	},
	components: {
	}
}
</script>


<style lang="scss" scoped>

.contact {
	max-width: 1200px;
	margin: 50px auto 50px;
	text-align: left;
	overflow: hidden;
	padding: 0 10px;
}

h3 {
	margin: 40px 0 0;
	font-size: 25px;
	font-family: 'Oswald', sans-serif;
	font-weight: normal;
	color: #1a2b3b;
}

h3:first-child {
	margin: 0;
}

.contact-container {
	width: 80%;
	margin: auto;
}

.form-label {
	font-size: 20px;
	margin-top: 30px;
	font-family: 'Avenir', Helvetica, Arial, sans-serif;
}

input, textarea{
	width: 100%;
	font-size: 18px;
	padding: 10px;
	font-family: 'Josefin Slab', serif;
	border: 1px solid rgb(200,200,200);
	border-left: 3px solid #304457;
}

textarea {
	resize: vertical;
}

.btn {
	font-family: 'Avenir', Helvetica, Arial, sans-serif;
	border: 1px solid #304457;
	color: #304457;
	background: #ececec;
	font-size: 18px;
	padding: 10px 20px;
	cursor: pointer;
}

.btn:hover {
	color: white;
	background: #304457;
}

input[name="captcha"] {
	width: 160px;
	float: left;
}

#captcha-refresh-btn {
	float: left;
	font-size: 18px;
	padding: 8px 10px;
}

.submit-btn {
	float: right;
}

@media screen and (max-width: 680px) {
	.contact-container {
		width: 95%;
	}
}
</style>
