<template>
<div class="home">
	<div class="pane">
		<div class="left">
			<div class="profile-img">
				<img alt="Profile Image" src="@/assets/profile.png" />
			</div>
		</div>
		
		<div class="right">
			<h3>How Could People Behave More Morally And Perform Better In The Digital Workplace?</h3>
			<p>
				Zhenyu Liao’s research primarily focuses on the areas of leadership, behavioral ethics and morality, social inequality, and online labor markets. 
			</p>
			<p>
				He studies how organizations in an emerging digital working environment could foster organizational members’ work effectiveness and creativity through nudging the process of moralization and simultaneously encouraging the ideation and implementation of novel work ideas. He has also endeavored to identify and reduce implicit social and economic barriers for creators and entrepreneurs in the innovation economy. Additionally, his research seeks to increase leadership effectiveness by studying leadership behaviors on a daily (or episodic) basis and followers’ momentary responses. Further, he has been intrigued by approaches to enhancing the work commitment of online labor platform workforces. 
			</p>

			<div class="links">
				<a target="_blank" class="link" href="https://zhenyuliao.com/_file/CV_Zhenyu Liao_22_NEU.pdf">Curriculum Vitae</a>
				<span class="link-delimiter">|</span>
				<a target="_blank" class="link" href="https://scholar.google.com/citations?user=iUM2rnQAAAAJ&hl=en">Google Scholar</a>
				<span class="link-delimiter">|</span>
				<a target="_blank" class="link" href="https://www.researchgate.net/profile/Zhenyu_Liao2">ResearchGate</a>
				<span class="link-delimiter">|</span>
				<a target="_blank" class="link" href="https://damore-mckim.northeastern.edu/people/zhenyu-liao/">Northeastern Homepage</a>
			</div>
		</div>
	</div>

	<h3 class="big"><span>Featured Research</span></h3>
	<ul class="narrow">
		<li class="research-item">
			<div class="research-icon">
				<img src="@/assets/open-book-gray.svg" />
			</div>
			<div class="research-content">
				<p class="research-title">Give and Take: An Episodic Perspective on Leader-Member Exchange</p>
				<p class="research-subtitle">- Journal of Applied Psychology, 2019</p>
				<p class="research-keyword">Episodic transactions, LMX relationship, Resource contributions, State work engagement, Momentary sense of obligation to reciprocate</p>
				<p class="research-link"><a target="_blank" href="https://www.researchgate.net/publication/328091958_Give_and_Take_An_Episodic_Perspective_on_Leader-Member_Exchange">Read >></a></p>
			</div>
		</li>
		<li class="research-item">
			<div class="research-icon">
				<img src="@/assets/open-book-gray.svg" />
			</div>
			<div class="research-content">
				<p class="research-title">Cleansing My Abuse: A Reparative Response Model of Perpetrating Abusive Supervisor Behavior</p>
				<p class="research-subtitle">- Journal of Applied Psychology, 2018</p>
				<p class="research-keyword">Abusive supervisor behavior, Moral cleansing, Moral attentiveness, Moral courage</p>
				<p class="research-link"><a target="_blank" href="https://www.researchgate.net/publication/323958891_Cleansing_My_Abuse_A_Reparative_Response_Model_of_Perpetrating_Abusive_Supervisor_Behavior">Read >></a></p>
			</div>
		</li>
		<li class="research-item">
			<div class="research-icon">
				<img src="@/assets/open-book-gray.svg" />
			</div>
			<div class="research-content">
				<p class="research-title">Why and When Leader’s Affective States Influence Employee Upward Voice</p>
				<p class="research-subtitle">- Academy of Management Journal, 2017</p>
				<p class="research-keyword">Employee voice, Affect, Leadership, LMX</p>
				<p class="research-link"><a target="_blank" href="https://www.researchgate.net/publication/284705812_Why_and_When_Leader's_Affective_States_Influence_Employee_Upward_Voice">Read >></a></p>
			</div>
		</li>
	</ul>

	<h3 class="big">Highlights</h3>
	<div class="highlights">
		<div class="backdrop"></div>
		<div class="highlight-container">
			<div class="highlight-box"
				v-for="(highlight, index) in highlights"
				v-bind:key="index">
				<div
					class="highlight"
					@mouseover="hoverHighlight(index)"
					@mouseleave="hoverHighlight(-1)">
					<a target="_blank" :href="highlight.link">
					<div class="img-box">
						<img :src="highlight.img" />
						<div class="overlay" :class="{'selected': isHighlightSelected(index)}"></div>
					</div>
					<div class="desc">
						<div class="title">{{highlight.title}}</div>
						<div class="source">{{highlight.src}}</div>
					</div>
					</a>
				</div>
			</div>
		</div>
	</div>

 </div>
</template>

<script>
//import Intro from '@/components/Intro'

export default {
	name: 'home',
	data () {
		return {
			selectedHighlight: -1,
			highlights: [
				// {
				// 	link: 'https://hbr.org/2018/04/when-your-boss-has-an-angry-outburst-what-do-they-do-next',
				// 	img: 'https://hbr.org/resources/images/article_assets/2018/04/mar18-03-umanoide-unsplash-energepic.com-pexels.jpg',
				// 	title: 'When Your Boss Has an Angry Outburst, What Do They Do Next?',
				// 	src: 'Harvard Business Review'
				// },
				{
					link: 'https://hbr.org/2019/01/research-when-being-close-to-your-employees-backfires',
					img: 'https://hbr.org/resources/images/article_assets/2019/01/Jan19_14_10188750-850x478.jpg',
					title: 'When Being Close to Your Employees Backfires',
					src: 'Harvard Business Review'
				},
				// {
				// 	link: 'https://source.wustl.edu/2018/04/funny-side-hard-edge-your-boss-behavior-matters-research-shows/',
				// 	img: 'https://source.wustl.edu/wp-content/uploads/2018/04/workplace-humor-rage.jpg',
				// 	title: 'Funny Side, Hard Edge: Your Boss’ Behavior Matters, Research Shows',
				// 	src: 'WUSTL Source'
				// },
				{
					link: 'https://hbr.org/2017/03/when-joking-with-your-employees-leads-to-bad-behavior',
					img: 'https://hbr.org/resources/images/article_assets/2017/03/mar17-17-638093154-850x478.jpg',
					title: 'When Joking with Your Employees Leads to Bad Behavior',
					src: 'Harvard Business Review'
				},
				{
					link: 'https://hbr.org/2020/08/intimidating-bosses-can-change-they-just-need-a-nudge',
					img: 'https://hbr.org/resources/images/article_assets/2020/08/Aug20_31_683980474.jpg',
					title: 'Intimidating Bosses Can Change — They Just Need a Nudge',
					src: 'Harvard Business Review'
				}
			]
		}
	},
	methods: {
		hoverHighlight (index) {
			this.selectedHighlight = index
		},
		isHighlightSelected(index) {
			return this.selectedHighlight === index
		}
	},
	components: {
	}
}
</script>

<style lang="scss" scoped>
h3 {
	margin: 40px 0 0;
	font-size: 30px;
	font-family: 'Open Sans', sans-serif;
	font-weight: normal;
	color: #1a2b3b;
}

h3.big {
	text-align: center;
	font-family: 'Oswald', sans-serif;
	font-weight: bold;
	font-size: 30px;
	padding-bottom: 10px;
	height: 50px;
	padding-top: 10px;
	width: 80%;
	margin: auto;
	color: #1a2b3b;
}

h3:first-child {
	margin: 0;
}

ul {
	list-style-type: none;
	padding: 0;
	margin: 0;
}

ul.narrow {
	width: 95%;
	margin: 20px auto 30px;
}

ul.narrow li {
	display: inline-block;
	margin: 15px 0;
}
a {
	color: #2c3e50;
}

.home {
	width: 1200px;
	margin: 50px auto 50px;
	text-align: left;
	overflow: hidden;
	padding: 0 10px;
	.pane {
		overflow: hidden;
		margin-bottom: 60px;
		display: flex;
	}

	.left {
		width: 30%;
		float: left;
		position: relative;

		.profile-img {
			width: 80%;
			height: 370px;
			overflow: hidden;
			position: absolute;
			top: 55%;
			transform: translateY(-50%);

			img {
				width: 125%;
				left: -14%;
				position:absolute;
			}
		}
	}

	.right {
		width: 70%;
		float: right;
		overflow: hidden;
		
		h3 {
			margin: 30px 0;
			color: #1a2b3b;
			font-family: 'Oswald', sans-serif;
		}

		p {
			font-family: 'Josefin Slab', serif;
			line-height: 1.5;
			font-size: 19px;
			color: #304457;
		}

		.links {
			overflow: hidden;
			font-family: 'Josefin Slab', serif;
		
			span{
				float: left;
				margin-right: 30px;
				font-size: 22px;
				color: #aaa;
			}
		
			.link {
				display: block;
				float: left;
				margin-right: 30px;
				color: #304457;
				font-size: 18px;
				text-decoration: none;
				border-bottom: 1px solid rgba(44, 62, 80, .3);
				transition: border-bottom .15s ease-in-out;
			}
			
			.link:hover {
				border-bottom: 1px solid #A31F34; //rgb(44, 62, 80);
			}
		}
		}

}

.research-item {
	width: 100%;
	.research-icon {
		float: left;
		width: 15%;
		padding: 20px;
		img {
			width: 50%;
			float: right;
			opacity: .5;
		}
	}
	
	.research-content {
		transition: border-left .25s ease-in-out;
		border-left: 1px solid #A31F34;
		padding-left: 15px;
		float: left;
		width: 85%;

		.research-title {
			margin: 2px 0;
			font-size: 20px;
			font-family: 'Avenir', Helvetica, Arial, sans-serif;
			color: #1a2b3b;
			font-style: italic;
			a {
				text-decoration: none;
			}
		}

		.research-subtitle {
			margin: 0 0 2px 40px;
			font-size: 14px;
			font-style: italic;
			display: none;
		}

		.research-keyword {
			margin: 12px 0 0;
			font-size: 19px;
			font-family: 'Josefin Slab', serif;
			color: #304457;
		}

		.research-link {
			display: inline-block;
			margin: 10px 0 5px;
			border-bottom: 1px solid rgba(44, 62, 80, .3);
			a {
				font-size: 14px;
				text-decoration: none;
				font-family: 'Avenir', Helvetica, Arial, sans-serif;
			}
			a:hover {
				border-bottom: 1px solid #A31F34; //rgb(44, 62, 80);
			}
		}
	}
}

.research-item:hover {
	.research-content {
		border-left: 1px solid #A31F34;
	}
}

.highlights {
	width: 100%;
	overflow: visible;
	margin: 20px 0;
	position: relative;
	overflow: visible;

	.highlight-container {
		width: 100%;
		overflow: hidden;
		padding-top: 30px;
		padding-bottom: 60px;
		
		.highlight-box {
			overflow: hidden;
			float: left;
			width: 33%;
		}
	}
	.backdrop {
		width: 80%;
		height: 400px;
		position: absolute;
		background: rgb(111, 114, 124);
		opacity: .1;
		left: 50%;
		transform: translateX(-50%);
		top: -40px;
		z-index: -1;
	}
}

.highlight {
	overflow: hidden;
	cursor: pointer;
	width: 90%;
	margin: auto;

	.img-box {
		width: 100%;
		height: 180px;
		float: left;
		position: relative;

		img {
			width: 100%;
			height: 100%;
		}

		.overlay {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: #2c3e50;
			opacity: .6;
			transition: opacity 0.3s;
		}

		.overlay.selected {
			opacity: 0;
		}
	}
	.desc {
		font-family: 'Noto Serif TC', serif;
		margin-top: 15px;
		width: 100%;
		float: left;

		.title {
			margin: 6px 0;
			height: 40px;
			font-size: 16px;
			color: #1a2b3b;
			font-family: 'Avenir', Helvetica, Arial, sans-serif;
			line-height: 1.3;
			margin-bottom: 10px;
		}
		.source {
			color: #444;
			font-size: 16px;
			font-style: italic;
			font-family: 'Josefin Slab', serif;
			display: inline;
			border-bottom: 1px solid #A31F34;
		//	padding-left: 80px;
		}
	}
}

.highlight:hover {
	opacity: 1;
}

.highlight-box:first-child {
	.highlight {
		float: left;
	}
}

.highlight-box:last-child {
	.highlight {
		float: right;
	}
}

@media screen and (max-width: 1200px) {
	.home {
		margin: 15px auto;
		width: 100%;
		.pane {
			display: block;
			
			.left {
				width: 25%;
				.profile-img {
					position: absolute;
					top: 30px;
					transform: none;
					max-height: 300px;
				}
			}
			.right {
				width: 75%;
				h3 {
					margin-top: 20px;
				}
			}
		}
		
		.research-content {
			width: 100%;
		}
		.research-icon {
			display: none;
		}
	}
}

@media screen and (max-width: 680px) {
	.home {
		margin: 15px auto;
		width: 100%;

		.pane {
			display: block;
			.left, .right {
				width: 100%;
			}
			.left{
				.profile-img {
					position: initial;
					width: 100%;
					height: 180px;
					//margin-bottom: 20px;
					text-align: center;
					img {
						position: initial;
						margin: auto;
						width: 160px;
					}
				}
			}
			.right {
				h3 {
					font-size: 25px;
				}
			}
		}
		.links {
			a {
				width: 100%;
				text-align: center;
				border-bottom: none!important;
				text-decoration: underline!important;
				line-height: 1.5;
			}
			.link-delimiter {
				display: none;
			}
		}
		
	}
	ul.narrow {
		width: 100%;
	}

	.highlights {
		width: 100%;
		overflow-x: scroll;
		overflow-y: hidden;

		.backdrop {
			display: none;
		}

		.highlight-container {
			width: 900px;
		}

		.highlight {
			.desc {
				.source {
					padding-left: 10px;
				}
			}
		}
	}
}
</style>



