import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Publications from './views/Publications.vue'
import Press from './views/Press.vue'
import Collaborators from './views/Collaborators.vue'
import Team from './views/Team.vue'
import Contact from './views/Contact.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: '/',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/publications',
      name: 'publications',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
	  // component: () => import(/* webpackChunkName: "about" */ './views/Publications.vue')
	  component: Publications
    },
    {
      path: '/press',
      name: 'press',
      component: Press
    },
    {
      path: '/collaborators',
      name: 'collaborators',
      component: Collaborators
    },
    {
      path: '/team',
      name: 'team',
      component: Team
    },
    {
      path: '/contact',
      name: 'contact',
      component: Contact
    }
]
})
